const { set } = require("store");


//カードレイアウトのラップを取得
const fadeInTargets = Array.from(document.querySelectorAll('.js-time-scroll__wrap'));
//先グラフのラップを取得
const lineChart = Array.from(document.querySelectorAll('.js-line-chart'));
//円グラフのラップを取得
const pieChart = Array.from(document.querySelectorAll('.js-pie-chart'));

const pieColorCode1 = '#FFB800'
const pieColorCode2 = '#D9D9D9'
if (fadeInTargets) {
    const callback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {


                //スクロールフェードインの処理
                let entryChildlen = Array.from(entry.target.querySelectorAll('.js-time-scroll'));
                let index = 0;
                let intervalId = setInterval(() => {
                    if (entryChildlen[index]) {
                        entryChildlen[index].classList.add('is-active');
                        index++;
                    }
                    if (index === entryChildlen.length) {
                        clearInterval(intervalId);
                    }
                }, 80);

                //数字カウントアップアニメーション
                entryChildlen.forEach((childlen) => {
                    if (childlen.querySelector('.js-countUp')) {

                        let countUpTarget = Array.from(childlen.querySelectorAll('.js-countUp'));

                        countUpTarget.forEach((targetEl) => {
                            //data-count-numberの値を変えることでカウントアップの値を変更できる
                            let countNumber = targetEl.getAttribute('data-count-number');
                            //乱数を50ミリ秒に一回描画することでカウントアップのアニメーションを実現
                            const countInterval = setInterval(() => {
                                targetEl.textContent = Math.floor(Math.random() * countNumber) + 1;
                            }, 50);
                            //カウントアップのアニメーションを1秒後に終了
                            setTimeout(() => {
                                clearInterval(countInterval);
                                targetEl.textContent = countNumber;
                            }, 1000);

                        });
                        //線グラフアニメーション
                        if (childlen.querySelector('.js-line-chart')) {
                            let lineCharts = Array.from(childlen.querySelectorAll('.js-line-chart'));
                            lineCharts.forEach((lineChart) => {
                                let widthTarget = lineChart.querySelector('.graf-1');
                                //data-count-numberの値を変えることで線グラフの長さ変更できる
                                let widthSize = lineChart.querySelector('.js-countUp').getAttribute('data-count-number') + '%';
                                setTimeout(() => {
                                    //線グラフのアニメーションを実行
                                    widthTarget.style.width = widthSize;
                                }, 200);
                            })
                        }
                        //円グラフアニメーション data-count-numberの値を変えることで円グラフの長さ変更できる
                        if (childlen.querySelector('.js-pie-chart')) {
                            let pieCharts = Array.from(childlen.querySelectorAll('.js-pie-chart'));
                            pieCharts.forEach((pieChart) => {
                                let pieTarget = pieChart.querySelector('.js-countUp').getAttribute('data-count-number');
                                let i = 0;
                                const pieInterval = setInterval(() => {
                                    pieChart.style.background = 'conic-gradient(' + pieColorCode1 + ' 0% ' + i + '%, ' + pieColorCode2 + ' ' + i + '% 100%)';
                                    i++;
                                    if (i == pieTarget) {
                                        clearInterval(pieInterval);
                                    }
                                }, 15)
                                // if (i == pieTarget) {
                                //     clearInterval(pieInterval);
                                //     console.log(i);
                                // }

                            })

                        }
                    }
                })

                //グラフアニメーション


                observer.unobserve(entry.target);
            }
        });
    }

    const options = {
        root: null,
        rootMargin: '50% 0px -20% 0px',
    };

    const observer = new IntersectionObserver(callback, options);

    fadeInTargets.forEach((target) => {
        observer.observe(target);
    });
}
