
const mapPins = Array.from(document.querySelectorAll('.js-mapModal-trigger'));
const modalContents = Array.from(document.querySelectorAll('.js-mapModal-content'));


mapPins.map((mapPin) => {
    //クリック処理
    mapPin.addEventListener('click', () => {

        modalContents.map((content) => { if (content.classList.contains('is-open')) { content.classList.remove('is-open') } })
        //data属性を取得
        let mapPinNumber = mapPin.getAttribute('data-modal-trigger');


        modalContents.map((content) => {
            if (content.id == mapPinNumber) {

                //一致しているものにis-openが既に付いているか。trueならclass削除 falseならis-open付与
                if (content.classList.contains('is-open')) {
                    content.classList.remove('is-open');
                } else {
                    content.classList.add('is-open')
                }
            }

        })
    })

    const closeBtns = Array.from(document.querySelectorAll('.c-map-modal-close'))
    closeBtns.forEach((closeBtn) => {
        closeBtn.addEventListener('click', () => {
            console.log(closeBtn)
            modalContents.map((items) => {
                if (items.classList.contains('is-open')) {
                    items.classList.remove('is-open')
                }
            })
        })

    })
})