import Simplemodal from '../../plugins/simplemodal.js';


//productページmap pinのモーダル
// const simplemodal = new Simplemodal(
//     {
//         trigger: 'data-modal-trigger',
//         backFixed: false,
//     }
// );

//freedomページのモーダル
const simplemodal2 = new Simplemodal(
    {
        trigger: 'data-simplemodal-trigger',
        backFixed: true,
    }
);