import Swiper from 'swiper';

var swiper01 = new Swiper('.js-swiper-01', {
    loop: true,
    slidesPerView: 2.5,
    spaceBetween: 24,
    centeredSlides: true,
    navigation: {
        nextEl: '.js-swiper-01-next',
        prevEl: '.js-swiper-01-prev',
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            centeredSlides: true,
        },
        599: {
            slidesPerView: 1.5,
            centeredSlides: true,
        },
        400: {
            slidesPerView: 1,
            centeredSlides: true,
        },
    
      },
})
    

    


