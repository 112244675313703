// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onEnter',
  // duration: 800,
  offset: 0
})
  // .addIndicators()
  .addTo(controller);

scene_hero.on('enter', () => {
  //triggerElementを過ぎたとき
});
scene_hero.on('leave', () => {
  //triggerElementを抜けたとき
});

//scale付きアニメーション
const scrollContents = document.querySelectorAll(".js-fade-target");

if (scrollContents) {
  scrollContents.forEach((e) => {
    let scroll_contents = new ScrollMagic.Scene({
      triggerElement: e,
      triggerHook: "onEnter",
      duration: 800,
      offset: 0,
    })
      // .addIndicators()
      .addTo(controller);

    scroll_contents.on("enter", () => {
      if (e) {
        e.classList.add("is-active");
      }
    });
  });
}

//ノーマルアニメーション
const scrollContents2 = document.querySelectorAll(".js-scroll");

if (scrollContents2) {
  scrollContents2.forEach((e) => {
    let scroll_contents2 = new ScrollMagic.Scene({
      triggerElement: e,
      triggerHook: "onEnter",
      duration: 800,
      offset: 0,
    })
      // .addIndicators()
      .addTo(controller);

    scroll_contents2.on("enter", () => {
      if (e) {
        e.classList.add("is-active");
      }
    });
  });
}

//時間差アニメーション
const scrollContents3 = Array.from(document.querySelectorAll(".js-time-scroll__wrap"));

// if (scrollContents3) {
//   //カウント
//   let index = 0;

//   //インスタンス化
//   let scroll_contents3 = new ScrollMagic.Scene({
//     triggerElement: scrollContents3[index],
//     triggerHook: "onEnter",
//     duration: 800,
//     offset: 0,
//   })
//     // .addIndicators()
//     .addTo(controller);

//   //スクロール時の処理
//   scroll_contents3.on("enter", () => {
//     if (scrollContents3[index]) {
//       const intervalId = setInterval(() => {
//         scrollContents3[index].classList.add('is-active');
//         index++;
//         if (index === scrollContents3.length) {
//           clearInterval(intervalId)
//         }
//       }, 100)
//     }
//   });
// }






// let index = 0
//         const intervalId = setInterval(() => {
//           e[index].classList.add('is-active');
//           index++;
//           if (index === loadingLines.length) {
//             clearInterval(intervalId)
//           }
//         }, 500)

// const loadingLines = Array.from(document.querySelectorAll('.js-fade-time-target'))
// if (loadingLines) {
//   let index = 0
//   const intervalId = setInterval(() => {
//     loadingLines[index].classList.add('is-active');
//     index++;
//     if (index === loadingLines.length) {
//       clearInterval(intervalId)
//     }
//   }, 500)
// }