class Inversion {
    constructor(obj) {
        const inversionTarget = Array.from(document.querySelectorAll(obj.hook));
        if (inversionTarget) {
        
            inversionTarget.map((target) => {
                target.addEventListener('mouseover', () => {
                    const targetParent = target.querySelector('.js-inversion-parent');
                    targetParent.classList.add('is-inversion');
                })
                target.addEventListener('mouseleave', () => {
                    const targetParent = target.querySelector('.js-inversion-parent');
                    targetParent.classList.remove('is-inversion');
                })
            })
        }
    }
}

//親要素をhoverしたときに子要素の色を反転させる
//親にはjs-inversion、子にはjs-inversion-parentをつける
const inversion = new Inversion({
    hook: '.js-inversion'
});
