import Accordion from '../../plugins/accordion.js';
const elements = document.querySelectorAll(".js-accordion");
for (const element of elements) {
  let accordion2 = new Accordion(element, {
    // defaultOpenPanels: [0],
    multipleOpen: true,
    // onOpen: test,
    // onClose: fuga
  });
}

// let accordion = new Accordion(".js-accordion2", {
//   defaultOpenPanels: [0],
//   onOpen: hoge,
//   onClose: fuga
// });

function test(trigger, panel) {
  trigger.style.color = '#f00'
}
function fuga(trigger, panel) {
  trigger.style.color = '#000'

}

// accordion.destroy()
// accordion.init()



//言語切り替え用矢印
const langChangeBtn = document.querySelector('.js-lang-change');
if (langChangeBtn) {
  langChangeBtn.addEventListener('click', () => {
    const arrow = document.querySelector('.js-arrow');
    if (langChangeBtn.getAttribute('aria-expanded') === 'true') {
      arrow.classList.add('is-open');
    } else {
      arrow.classList.remove('is-open');
    }
  });
}

let accordionBtns = Array.from(document.querySelectorAll('.js-header-accordion'));
if (accordionBtns) {
  accordionBtns.map((btn) => {
    btn.addEventListener('click', () => {
      if (btn.getAttribute('aria-expanded') === 'true') {
        btn.querySelector('.js-accordion-arrow').classList.add('is-open');
      } else {
        btn.querySelector('.js-accordion-arrow').classList.remove('is-open');
      }
    })
  })
}

//十字ボタン
const openCloseBtn = Array.from(document.querySelectorAll('.js-accordion-button'));

if (openCloseBtn) {
  openCloseBtn.map((btn) => {
    btn.addEventListener('click', () => {
      if (btn.getAttribute('aria-expanded') === 'true') {
        btn.querySelector('.js-accordion-vertical-line').classList.add('is-open');
      } else {
        btn.querySelector('.js-accordion-vertical-line').classList.remove('is-open');
      }
    });
  });
}