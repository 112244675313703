import isMobile from '../../plugins/is-mobile.js';
import addEventListenerMultiType from './multiEventLister.js';

class MegaMenu {
  constructor(options) {
    this.body = document.querySelector('body');
    this.targets = document.querySelectorAll('.megamenu-wrapper');
    this.autoClose = options.autoClose;
  }

  init() {
    this.attachEvent();
  }

  toggle(target) {
    target.classList.toggle('is-megaMenu--show');
    this.body.classList.add('is-megaMenu--showPage');
  }
  toggleSP(target) {
    if (target.classList.contains('is-megaMenu--show')) {
      target.classList.remove('is-megaMenu--show');
    } else {
      if (this.autoClose) {
        for (let target of this.targets) {
          target.classList.remove('is-megaMenu--show');
        }
      }
      target.classList.add('is-megaMenu--show');
    }
  }

  attachEvent() {
    for (let target of this.targets) {
      if (!isMobile(768)) {
        addEventListenerMultiType(target, 'mouseenter touchstart', () => {
          this.toggle(target);

          const nextElement = target.nextElementSibling;
          const prevElement = target.previousElementSibling;

          if (nextElement && nextElement.lastElementChild) {
            nextElement.lastElementChild.style.zIndex = '500';
          }

          if (prevElement && prevElement.lastElementChild) {
            prevElement.lastElementChild.style.zIndex = '500';
          }
        }, false);

        addEventListenerMultiType(target, 'mouseleave touchend', () => {
          this.toggle(target);
          this.body.classList.remove('is-megaMenu--showPage');

          const nextElement = target.nextElementSibling;
          const prevElement = target.previousElementSibling;

          if (nextElement && nextElement.lastElementChild) {
            nextElement.lastElementChild.removeAttribute('style');
          }

          if (prevElement && prevElement.lastElementChild) {
            prevElement.lastElementChild.removeAttribute('style');
          }
        }, false);
      }
    }
  }
}

const options = {
  'autoClose': 1
}
const megaMenu = new MegaMenu(options);
megaMenu.init();

