// const colorChangeTarget = Array.from(document.querySelectorAll('.js-scrollColler-target'));
// const monitoringTarget = Array.from(document.querySelectorAll('.js-scroll-monitoring'));


const lowerIndex = document.querySelector('.js-memory-list')

if (lowerIndex) {
    const lowerIndexTopIndexLink = lowerIndex.querySelectorAll('.js-scrollColler-target')
    // console.log(lowerIndexTopIndexLink)
    const headings = Array.from(lowerIndexTopIndexLink).map((link) => {
        const href = link.getAttribute('href')
        const heading = document.querySelector(href)
        return heading
    })
    // console.log(headings)

    const options = {
        root: null,
        rootMargin: '-6% 0px -96% 0px',
        threshold: 0,
    }
    const options2 = {
        root: null,
        rootMargin: '0% 0% -20% 0%',
        threshold: 1,
    }
    const callback = (entries, object) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const target = entry.target
                const href = `#${target.id}`
                const link = document.querySelector(`.js-scrollColler-target[href="${href}"]`)
                const links = document.querySelectorAll('.js-scrollColler-target')
                links.forEach((link) => {
                    link.classList.remove('is-active')
                })
                link.classList.add('is-active')
            }
        })
    }

    headings.forEach((heading) => {
        const observer = new IntersectionObserver(callback, options)
        observer.observe(heading)
    })
}

//     //最後の要素
//     const headingLastIndex = headings.slice(-1)[0];
//     const observer2 = new IntersectionObserver(callback, options2);
//     observer2.observe(headingLastIndex)

// }
